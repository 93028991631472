import { createRouter, createWebHistory } from 'vue-router';
import adminView from "@/layout/admin.vue"
import loginAdmin from "@/pages/login.vue"

//Quản lý công việc
import JobManager from "@/pages/jobManager/index.vue"
import ListJob from "@/pages/jobManager/list_job.vue"
import ListJobFilter from "@/pages/jobManager/filter_page.vue"
import DetailJobAccount from "@/pages/jobManager/detail_job_account.vue"
import DetailJobPublic from "@/pages/jobManager/detail_job_public.vue"

//Quản lý giao dịch
import TransactionManager from "@/pages/transactionManager/index.vue"

//Quản lý sản phẩm
import Product from "@/pages/productManager"
import StoreProduct from "@/pages/productManager/account_store.vue"
import FormProduct from "@/pages/productManager/form.vue"

//Quản lý khách hàng
import ListMember from "@/pages/memberManager"
import DetailMember from "@/pages/memberManager/detail.vue"

//Hỗ trợ
import Support from "@/pages/support"
import SupportCustomerDetail from "@/pages/support/detail.vue"
import SupportDetail from "@/pages/support/detail_support.vue"
import AddSupport from "@/pages/support/form.vue"

//Cài đặt chung
import Privacy from "@/pages/settings/index.vue"

const routes = [
    {
        path: "/",
        component: adminView,
        meta: 
            { 
                title: "Trang quản trị",
                requiresAuth: true
            },
        children: [
            //Quản lý công việc
            {
                path: "/",
                component: JobManager,
                meta: { title: "Quản lý công việc" },
            },
            {
                path: "/quan-ly-cong-viec/danh-sach",
                component: ListJob,
                meta: { title: "Danh sách công việc" },
            },
            {
                path: "/quan-ly-cong-viec/danh-sach/:slug",
                component: ListJobFilter,
                meta: { title: "Danh sách công việc" },
            },
            {
                path: "/quan-ly-cong-viec/mua-tai-khoan/:id",
                component: DetailJobAccount,
                meta: { title: "Chi tiết mua tài khoản" },
            },
            {
                path: "/quan-ly-cong-viec/public-ung-dung/:id",
                component: DetailJobPublic,
                meta: { title: "Chi tiết ứng dụng public" },
            },
            //Quản lý giao dịch
            {
                path: "/quan-ly-giao-dich",
                component: TransactionManager,
                meta: { title: "Quản lý giao dịch" },
            },
            //Quản lý sản phẩm
            {
                path: "/quan-ly-san-pham",
                component: Product,
                meta: { title: "Quản lý sản phẩm" },
            },
            {
                path: "/quan-ly-san-pham/kho-tai-khoan",
                component: StoreProduct,
                meta: { title: "Kho tài khoản" },
            },
            {
                path: "/quan-ly-san-pham/kho-tai-khoan/tao-moi",
                component: FormProduct,
                meta: { title: "Tạo mới tài khoản" },
            },
            {
                path: "/quan-ly-san-pham/kho-tai-khoan/chinh-sua/:id",
                component: FormProduct,
                meta: { title: "Chỉnh sửa tài khoản" },
            },
            //Quản lý khách hàng
            {
                path: "/quan-ly-khach-hang",
                component: ListMember,
                meta: { title: "Quản lý khách hàng" },
            },
            {
                path: "/quan-ly-khach-hang/chi-tiet/:id",
                component: DetailMember,
                meta: { title: "Chi tiết khách hàng" },
            },
            //Hỗ trợ
            {
                path: "/ho-tro",
                component: Support,
                meta: { title: "Danh sách hỗ trợ" },
            },
            {
                path: "/ho-tro/khach-hang/:cus_id",
                component: SupportCustomerDetail,
                meta: { title: "Danh sách hỗ trợ" },
            },
            {
                path: "/ho-tro/chi-tiet/:sup_id",
                component: SupportDetail,
                meta: { title: "Chi tiết hỗ trợ" },
            },
            {
                path: "/ho-tro/tao-moi",
                component: AddSupport,
                meta: { title: "Tạo mới hỗ trợ" },
            },
            {
                path: "/ho-tro/tra-loi/:cus_id",
                component: AddSupport,
                meta: { title: "Trả lời hỗ trợ" },
            },
            //Cài đặt chung
            {
                path: "/cai-dat-chung",
                component: Privacy,
                meta: { title: "Cài đặt chung" },
            },
        ]
    },
    {
        path: "/login-admin",
        component: loginAdmin,
        meta: { title: "Trang đăng nhập quản trị viên" },
    },
    {
        path: '/:catchAll(.*)',
        component: () => import('@/pages/404.vue'),
        meta: { title: "404" },
      },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach((to, from, next) => {
    window.scrollTo(0, 0);
    next();
});

// Navigation guard
// router.beforeEach((to, from, next) => {
//     const isLoggedIn = localStorage.getItem('admin')
//     if (to.matched.some(route => route.meta.requiresAuth) && !isLoggedIn) {
//         next('/login-admin');
//     } else {
//         next();
//     }
// });
export default router;