<template>
    <div class="wp-member">
        <h5>Quản lý khách hàng</h5>
        <div class="breadcrumb">
            <span>Quản lý khách hàng</span>
            <span style="margin: 0 8px;"><img src="@/assets/icons/caret-right.png" alt=""></span>
            <span class="active">Danh sách khách hàng</span>
        </div>
        <el-row class="align-items-center" style="margin-bottom: 18px;">
            <el-col :span="12" class="d-flex align-items-center" style="position: relative;">
                <el-form
                    ref="ruleFormRef"
                    status-icon
                    class="form_search"
                    @submit.prevent="setPage(1)"
                >
                    <img src="@/assets/icons/search.png" alt="">
                    <el-form-item>
                        <el-input type="text" v-model="keySearch" placeholder="Search"/>
                    </el-form-item>
                </el-form>
            </el-col>
            <el-col :span="12"></el-col>
        </el-row>

        <el-row>
            <el-col :span="24">
                <el-table :data="tableData" style="width: 100%;" height="700" @row-click="redirectDetail">
                    <el-table-column header-align="center" align="center" fixed label="Ảnh" width="218">
                        <template #default="scope">
                            <img :src="scope.row.avatar" alt="" style="width: 80px; height: 80px; border-radius: 62px; object-fit: cover;" @error="handleError">
                        </template>
                    </el-table-column>
                    <el-table-column header-align="center" label="Tên khách hàng" width="456">
                        <template #default="scope">
                            <span>{{ scope.row.first_name }} {{ scope.row.name }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column header-align="center" align="center" label="Mã khách hàng" width="180">
                        <template #default="scope">
                            <div class="table_code">{{ scope.row.member_code }}</div>
                        </template>
                    </el-table-column>
                    <el-table-column header-align="center" align="center" prop="user_name" label="Tên đăng nhập" min-width="210"/>
                    <el-table-column header-align="center" align="center" prop="email" label="Email" min-width="210"/>
                    <el-table-column header-align="center" align="center" label="Action">
                        <template #default="scope">
                            <button><img src="@/assets/icons/trash.png" alt=""></button>
                        </template>
                    </el-table-column>
                </el-table>
                <div class="wp-paginate">
                    <p>Hiển thị {{ (page.currentPage - 1) * page.pageSize + 1 }}-{{ page.currentPage * page.pageSize > page.totalData ? page.totalData : page.currentPage * page.pageSize }} trên {{ page.totalData }}</p>
                    <Pagination
                        :page="page"
                        @set-page="setPage"
                    />
                </div>
            </el-col>
        </el-row>
    </div>
</template>

<script>
import Pagination from '../components/pagination.vue'
export default {
    components: {
        Pagination
    },
    data() {
        return {
            keySearch: '',
            tableData: [],
            page: {
                totalPage: 0,
                totalData: null,
                pageSize: 10,
                currentPage: this.$route.query.page || 1,
            },
            stt_key: ''
        }
    },
    mounted() {
        this.getListMemberAdmin()
    },
    methods: {
        setPage(val) {
            this.page.currentPage = val;
            this.getListMemberAdmin()
            document.getElementsByClassName('el-scrollbar__wrap')[0].scrollTo({
                top: 0,
                behavior: "smooth"
            });
        },
        redirectDetail(row) {
            this.$router.push('/quan-ly-khach-hang/chi-tiet/' + this.encode(row.id))
        },
        async getListMemberAdmin() {
            const data = {
                page : this.page.currentPage,
                per_page: this.page.pageSize
            };
            this.startLoading();
            await this.$axios.get('/api/admin/getListMemberAdmin',{
                params: {
                    key: this.keySearch,
                    page: data.page,
                    per_page: data.per_page
                },
                headers: {
                    Authorization: "bearer " + window.localStorage.getItem('admin'),
                }
            }).then(res => {
                this.tableData = res.data.data.data;
                this.page.totalPage = res.data.data.total_page;
                this.page.totalData = res.data.data.total_element;
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();
        },
    }
}
</script>
<style lang="css" scoped>
    h5 {
        color: #515151;
        font-size: 24px;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: 0.24px;
        margin-bottom: 10px;
    }

    .breadcrumb {
        font-family: 'Public Sans', sans-serif;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.07px;
    }

    .breadcrumb .active {
        color: #2367EC;
    }

    button {
        background: none;
        border: none;
    }

    .el-form {
        position: relative;
        width: 200px;
    }

    .el-form img {
        z-index: 2;
        position: absolute;
        top: 12px;
        left: 8px;
    }

    .el-table {
        border-top-right-radius: 20px;
        border-top-left-radius: 20px;
    }

    .table_code {
        color: #3FA5FF;
        font-weight: 500;
    }

    .wp-paginate {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 18px 24px;
        background-color: #FFF;
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
    }

    .wp-paginate p {
        font-size: 14px;
        font-weight: 500;
        color: #667085;
        margin-bottom: 0;
        font-family: 'Public Sans', sans-serif;
    }

    .dropdown button {
        font-family: 'Public Sans', sans-serif;
        width: 193px;
        height: 40px;
        border-radius: 15px;
        background: #FFF;
        box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.10);
        padding: 0 4px;
        color: #515151;
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 0.07px;
    }

    .dropdown:last-child button {
        margin-left: 16px;
    }

    .dropdown-toggle::after {
        margin-left: 2.255em;
    }

    .dropdown-toggle:focus,
    .dropdown-toggle:active {
        color: #515151 !important;
        background: #FFF !important;
    }

    .dropdown-menu {
        border-radius: 20px;
        background: #FFF;
        width: 320px;
        padding: 10px 12px 15px;
        margin-top: 5px !important;
    }

    .dropdown-menu li {
        border-radius: 32px;
        background: #F4F4F4;
        font-size: 15px;
        text-align: center;
        margin-bottom: 8px;
        height: 36px;
    }

    .dropdown-menu li a {
        font-weight: 500;
        height: 100%;
        color: #515151;
    }

    .dropdown-item:focus, .dropdown-item:hover {
        background-color: transparent;
    }
</style>

<style>
    .wp-member .form_search .el-input__wrapper,
    .wp-member .form_search .el-input__wrapper:hover,
    .wp-member .form_search .el-input__wrapper.is-focus {
        box-shadow: none;
        height: 40px;
        padding-left: 40px;
        border-radius: 15px;
        border: 1px solid #E0E2E7;
    }

    .wp-member .form_search.el-form .el-form-item {
        margin-bottom: 0;
    }

    .wp-member .el-table th.el-table__cell {
        height: 56px;
        color: #515151;
        font-size: 15px;
        font-weight: 500;
    }

    .wp-member .el-table .el-table__cell {
        padding: 11px 0;
    }

    .wp-member .el-table td {
        color: #515151;
        font-size: 14px;
        font-weight: 500;
        cursor: pointer;
    }
</style>