<template>
    <div class="wp-add-proj">
        <h5>Hỗ trợ</h5>
        <div class="d-flex align-items-center justify-content-between">
            <div class="breadcrumb">
                <span>Hỗ trợ</span>
                <span style="margin: 0 8px;"><img src="@/assets/icons/caret-right.png" alt=""></span>
                <span>Danh sách hỗ trợ</span>
                <span style="margin: 0 8px;"><img src="@/assets/icons/caret-right.png" alt=""></span>
                <span class="active">Chi tiết</span>
            </div>
        </div>

        <div class="wp-detail-chat">
            <div class="d-flex align-items-center mb-3">
                <router-link to="/ho-tro">
                    <img src="@/assets/icons/back_ico.png" alt="" class="back_ico">
                </router-link>
                <p class="ttl--head">Tạo tin nhắn mới</p>
            </div>
            <el-form 
                ref="ruleForm"
                :rules="rules"
                :model="ruleForm"
                @submit.prevent="sendSupport('ruleForm')"
            >
                <p class="label label--rq">Người nhận</p>
                <div class="dropdown">
                    <button class="btn" type="button" id="broker" data-bs-toggle="dropdown" aria-expanded="false" :disabled="replyPage">
                        <div v-if="!replyPage && isEmptyCustom(userChosse)">
                            <img src="@/assets/icons/user_ico.png" alt="" class="user_ico">
                            <span class="note">Nhấn để chọn khách hàng</span>
                        </div>
                        <div v-else-if="!replyPage && !isEmptyCustom(userChosse)">
                            <img :src="userChosse.avatar" alt="" class="user_ico">
                            <span class="note">{{ userChosse.name }} - {{ userChosse.user_name }}</span>
                        </div>
                        <div v-else>
                            <img :src="memberDetail.avatar" alt="" class="user_ico">
                            <span class="note">{{ memberDetail.name }} - {{ memberDetail.user_name }}</span>
                        </div>
                    </button>
                    <ul class="dropdown-menu add_member" aria-labelledby="broker">
                        <h5>Chọn người nhận</h5>
                        <el-form
                            ref="formLogin"
                            style="margin-bottom: 10px;"
                            @submit.prevent="getListMemberAdmin"
                        >
                            <el-form-item>
                                <el-input type="text" placeholder="Search" v-model="keySearch"/>
                            </el-form-item>
                        </el-form>
                        <div class="wp-member">
                            <li v-for="(item, index) in listMember" :key="index">
                                <a class="dropdown-item" href="#" @click="userChosse = item, ruleForm.member_id = item.id">
                                    <img :src="item.avatar" alt="" class="user_ico" @error="handleError">
                                    <span>{{ item.name }}</span>
                                    <span> - {{ item.user_name }}</span>
                                </a>
                            </li>
                        </div>
                    </ul>
                </div>
                <div class="d-flex align-items-center justify-content-between">
                    <el-form-item prop="title" label="Tiêu đề" style="flex-basis: 80%;">
                        <el-input type="text" placeholder="Nhập tiêu đề" v-model="ruleForm.title"></el-input>
                    </el-form-item>
                    <el-form-item prop="level" label="Độ ưu tiên" style="flex-basis: 17%;">
                        <el-select clearable filterable placeholder="Chọn độ ưu tiên" v-model="ruleForm.level">
                            <el-option key="1" label="Thấp" value="1" />
                            <el-option key="2" label="Bình thường" value="2" />
                            <el-option key="3" label="Cao" value="3" />
                        </el-select>
                    </el-form-item>
                </div>
                <p class="label label--rq">Nội dung tin nhắn</p>
                <CKeditor 
                    :editorData="ruleForm.content"
                    @getData="getDataContent"
                />
                <p class="label mt-2">File đính kèm</p>
                <input type="file" id="file" multiple ref="fileUpload" name="file" class="d-none" @change="uploadFile">
                <div class="d-flex align-items-center" @click="$refs.fileUpload.click()">
                    <button class="btn_upload" type="button">Upload</button>
                    <span class="file_note">jpg, png, svg, zip, pdf,...</span>
                </div>
                <div class="list-file">
                    <a :href="$storage + item.path_temp" target="_blank" class="wp-file" v-for="(item, index) in listFileUpload" :key="index">
                        <div class="d-flex align-items-center">
                            <img src="@/assets/icons/note.png" alt="" class="note_ico">
                            <span class="file_name">{{ item.file_name }}</span>
                        </div>
                        <button><img src="@/assets/icons/down_ico.png" alt=""></button>
                    </a>
                </div>
                <div class="wp-btn">
                    <button class="btn_send">Gửi tin nhắn</button>
                    <button class="btn_cancel" type="button" @click="$router.go(-1)">Hủy bỏ</button>
                </div>
            </el-form>
        </div>
    </div>
</template>
<script>
import { handleError } from 'vue';
import CKeditor from '../components/ckeditor.vue'
export default {
    components: { CKeditor},
    data() {
        return {
            listMember: [],
            memberDetail: [],
            keySearch: '',
            replyPage: false,
            ruleForm: {
                member_id: null,
                title: "",
                content: "",
                level: null,
                files:[]
            },
            rules: {
                title: [{required: true, message: "Vui lòng nhập tiêu đề!", trigger: ["blur", "change"]}],
                level: [{required: true, message: "Vui lòng chọn mức độ ưu tiên!", trigger: ["blur", "change"]}],
            },
            listFileUpload: [],
            userChosse: []
        }
    },
    mounted() {
        this.getListMemberAdmin()
        if(this.$route.path.includes('/ho-tro/tra-loi/')) {
            this.getDetailMember(this.decode(this.$route.params.cus_id))
            this.replyPage = true
            this.ruleForm.member_id = this.decode(this.$route.params.cus_id)
        }
    },
    methods: {
        getDataContent(data) {
            this.ruleForm.content = data
        },
        async uploadFile() {
            let formData = new FormData();
            if(event.target.files) {
                for(let i = 0; i < event.target.files.length; i++) {
                    formData.append("file[]", event.target.files[i]);
                }
            }
            formData.append('path', 'support/file')
            this.startLoading();
            await this.$axios.post('/uploadFile', formData,
                {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: "bearer " + window.localStorage.getItem('admin'),
                }
            }).then(res => {
                this.listFileUpload = res.data.data
                this.ruleForm.files = this.listFileUpload.map(item => item.path)
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", err.response.data.error);
                    }
                }
            })
            this.endLoading();
        },
        async getListMemberAdmin() {
            this.startLoading();
            await this.$axios.get('/api/admin/getListMemberAdmin',{
                params: {
                    key: this.keySearch,
                    page: 1,
                    per_page: 150
                },
                headers: {
                    Authorization: "bearer " + window.localStorage.getItem('admin'),
                }
            }).then(res => {
                this.listMember = res.data.data.data;
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();
        },
        async getDetailMember(id) {
            this.startLoading();
            await this.$axios.get('/api/admin/getMemberDetail',{
                params: {
                    id: id
                },
                headers: {
                    Authorization: "bearer " + window.localStorage.getItem('admin'),
                }
            }).then(res => {
                this.memberDetail = res.data.data;
            })
            this.endLoading();
        },
        async sendSupport(formName) {
            this.$refs[formName].validate(async (valid) => {
                if (valid) {
                    if(this.isEmptyCustom(this.ruleForm.content)) {
                        this.showNotify("warning", "Thất bại", "Vui lòng nhập nội dung tin nhắn!");
                    } else {
                        this.startLoading();
                        await this.$axios.post('/api/admin/createSupport',this.ruleForm, {
                            headers: {
                                Authorization: "bearer " + window.localStorage.getItem('admin'),
                            }
                        }).then(res => {
                            this.showNotify("success", "Thành công", 'Gửi tin nhắn hỗ trợ thành công');
                            this.$router.push('/ho-tro/khach-hang/' + this.encode(this.ruleForm.member_id))
                        }).catch(err => {
                            this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                        })
                        this.endLoading();
                    }
                } else {
                    this.showNotify("warning", "Thất bại", "Vui lòng nhập đầy đủ thông tin!");
                }
            });
        }
    }
}
</script>
<style lang="css" scoped>
    h5 {
        color: #515151;
        font-size: 24px;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: 0.24px;
        margin-bottom: 10px;
    }

    .breadcrumb {
        font-family: 'Public Sans', sans-serif;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.07px;
    }

    .breadcrumb .active {
        color: #2367EC;
    }

    .wp-detail-chat {
        border-radius: 20px;
        background: #FFF;
        padding: 20px;
        margin-top: 20px;
    }

    img.back_ico {
        width: 24px;
        height: 24px;
        margin: 8px;
    }

    .wp-content {
        margin-left: 50px;
    }

    p.ttl--head {
        color: #515151;
        font-size: 20px;
        font-weight: 600;
        line-height: 150%;
        margin: 0 0 0 10px;
    }

    p.file {
        position: relative;
        color: #515151;
        font-size: 18px;
        font-weight: 600;
        line-height: 140%;
        padding-left: 30px;
        margin: 10px 0 0 5px;
    }

    p.file::before {
        content: '';
        position: absolute;
        width: 20px;
        height: 20px;
        left: 0;
        top: 3px;
        background-image: url(@/assets/icons/file_down.png);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
    }

    .list-file {
        display: flex;
        align-items: center;
        margin-top: 15px;
        padding-bottom: 20px;
        border-bottom: 1px solid #EBEDF4;
    }

    .wp-file {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-basis: 30%;
        border-radius: 40px;
        background:rgba(240, 48, 48, 0.20);
        padding: 10px;
        color: #515151 !important;
        font-size: 15px;
        font-weight: 500;
        line-height: normal;
        text-decoration: none !important;
        margin-right: 10px;
    }

    span.file_name {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
    }

    .wp-file:last-child {
        margin-right: 0;
    }

    .wp-file img {
        width: 20px;
        height: 20px;
    }

    .wp-file .note_ico {
        width: 25px;
        height: 25px;
        margin-right: 10px;
    }

    .btn_upload {
        color: #EBEDF4;
        text-align: center;
        font-size: 16px;
        font-weight: 500;
        line-height: normal;
        border-radius: 20px;
        background: #515151;
        padding: 9px 15px;
        margin-right: 10px;
    }

    span.file_note {
        color: #9B9B9B;
        font-size: 15px;
        line-height: 100%;
        border-radius: 20px;
        background: #EBEDF4;
        display: inline-block;
        width: 100%;
        padding: 12px;
        cursor: pointer;
    }

    p.label {
        margin-bottom: 5px;
        color: #515151;
        font-weight: 500;
        padding-left: 10px;
        position: relative;
    }

    p.label--rq::before {
        content: '*';
        position: absolute;
        color: #f56c6c;
        left: 0
    }

    .wp-btn {
        margin-top: 20px;
    }

    .wp-btn button {
        text-align: center;
        font-size: 16px;
        font-weight: 600;
        line-height: normal;
        border-radius: 54px;
        padding: 10px 20px;
    }
    
    .btn_send {
        color: #FFF;
        background: #F03030;
    }

    .btn_cancel {
        color: #F03030;
        background: #EBEDF4;
        margin-left: 10px;
    }

    .dropdown button {
        width: 100%;
        max-width: 800px;
        text-align: left;
        border-radius: 15px;
        background: #EBEDF4 !important;
        padding: 12px 10px;
        border: none !important;
        margin-bottom: 10px;
    }

    img.user_ico {
        width: 40px;
        height: 40px;
        margin-right: 10px;
        object-fit: contain;
    }

    ul.add_member {
        width: 100%;
        max-width: 800px;
        padding: 10px 20px;
        border-radius: 20px;
    }

    ul.add_member li a {
        background: #EBEDF4 !important;
        padding: 12px 10px;
        border-radius: 20px;
    }

    ul.add_member li a span {
        font-size: 14px;
        font-weight: 500;
        color: #515151 !important;
    }

    ul.add_member li {
        margin-bottom: 10px;
    }

    ul.add_member li:last-child {
        margin-bottom: 0;
    }

    .wp-member {
        max-height: 400px;
        overflow: auto
    }

    .wp-member::-webkit-scrollbar {
        width: 0;
    }

    span.note {
        color: #AFAFAF;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.07px;
    }
</style>