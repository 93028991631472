<template>
    <div class="wp-add-proj">
        <h5>Hỗ trợ</h5>
        <div class="d-flex align-items-center justify-content-between">
            <div class="breadcrumb">
                <span>Hỗ trợ</span>
                <span style="margin: 0 8px;"><img src="@/assets/icons/caret-right.png" alt=""></span>
                <span>Danh sách hỗ trợ</span>
                <span style="margin: 0 8px;"><img src="@/assets/icons/caret-right.png" alt=""></span>
                <span class="active">Chi tiết</span>
            </div>
        </div>

        <div class="wp-detail-chat">
            <div class="d-flex align-items-center justify-content-between mb-3">
                <div class="d-flex align-items-center">
                    <router-link :to="'/ho-tro/khach-hang/' + encode(dataDetail.member_id)">
                        <img src="@/assets/icons/back_ico.png" alt="" class="back_ico">
                    </router-link>
                    <p class="ttl--head">Chi tiết tin nhắn</p>
                </div>
                <p class="stt stt--gr" v-if="dataDetail.level == 1">Thấp</p>
                <p class="stt stt--yl" v-else-if="dataDetail.level == 2">Bình thường</p>
                <p class="stt stt--red" v-else-if="dataDetail.level == 3">Cao</p>
            </div>
            <div class="d-flex align-items-center justify-content-between mb-2">
                <div v-if="dataDetail.user_id != 0">
                    <img src="@/assets/icons/logo_ico.png" alt="" class="avatar avatar--adm">
                    <span class="name">Aabapp admin:</span>
                </div>
                <div v-else>
                    <img :src="dataDetail.avatar" alt="" class="avatar">
                    <span class="name">{{dataDetail.name}}:</span>
                </div>
                <p class="time">{{ formateTime(dataDetail.created_at) }}</p>
            </div>
            <div class="wp-content">
                <p class="ttl">{{ dataDetail.title }}</p>
                <p class="content ck-content" v-html="dataDetail.content"></p>
                <p class="file">Tệp đính kèm</p>
                <div class="list-file">
                    <a :href="$storage + item.url" target="_blank" class="wp-file" v-for="(item, index) in dataDetail.files" :key="index">
                        <div class="d-flex align-items-center">
                            <img src="@/assets/icons/note.png" alt="" class="note_ico">
                            <span class="file_name">{{ item.old_name }}</span>
                        </div>
                        <button><img src="@/assets/icons/down_ico.png" alt=""></button>
                    </a>
                </div>
            </div>
            <div class="text-end" v-if="dataDetail.user_id == 0">
                <button class="reply" @click="$router.push('/ho-tro/tra-loi/' + encode(dataDetail.member_id))">+ Trả lời</button>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            content: '',
            id: this.decode(this.$route.params.sup_id),
            dataDetail: [],
            ruleForm: {
                content: ''
            },
        }
    },
    watch: {
        "$route": function (url) {
            if(url.path.includes('/ho-tro/chi-tiet') && url.params.sup_id) {
                this.id = this.decode(url.params.sup_id)
                this.getSupportDetail();
            }
        },
    },
    mounted() {
        this.getSupportDetail()
    },
    methods: {
        async getSupportDetail() {
            this.startLoading();
            await this.$axios.get('/api/admin/getSupportDetail',{
                params: {
                    id: this.id,
                },
                headers: {
                    Authorization: "bearer " + window.localStorage.getItem('admin'),
                }
            }).then(res => {
                this.dataDetail = res.data.data
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();
        },
    }
}
</script>
<style lang="css" scoped>
    h5 {
        color: #515151;
        font-size: 24px;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: 0.24px;
        margin-bottom: 10px;
    }

    .breadcrumb {
        font-family: 'Public Sans', sans-serif;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.07px;
    }

    .breadcrumb .active {
        color: #2367EC;
    }

    .wp-detail-chat {
        border-radius: 20px;
        background: #FFF;
        padding: 20px;
        margin-top: 20px;
    }

    img.back_ico {
        width: 24px;
        height: 24px;
        margin: 8px;
    }

    .wp-content {
        margin-left: 50px;
    }

    p.ttl--head {
        color: #515151;
        font-size: 20px;
        font-weight: 600;
        line-height: 150%;
        margin: 0 0 0 10px;
    }

    p.stt {
        border-radius: 40px;
        text-align: center;
        font-size: 15px;
        font-weight: 400;
        line-height: 100%;
        margin-bottom: 0;
        padding: 5px 10px;
    }

    .stt--yl {
        color: #F58E16 !important;
        background: rgba(245, 142, 22, 0.20);
    }

    .stt--gr {
        color: #1EDB80 !important;
        background:rgba(30, 219, 128, 0.20);
    }

    .stt--red {
        color: #F03030 !important;
        background:rgba(240, 48, 48, 0.20);
    }

    img.avatar {
        width: 45px;
        height: 45px;
        border-radius: 33.75px;
        object-fit: cover;
    }

    img.avatar--adm {
        border: 1.25px solid #EBEDF4;
        padding: 5px;
    }

    span.name {
        color: #515151;
        font-size: 18px;
        font-weight: 500;
        line-height: 140%;
        margin-left: 10px;
    }

    p.time {
        color: #515151;
        font-size: 16px;
        font-weight: 500;
        line-height: 140%;
        margin: 0;
    }

    p.ttl {
        color: #515151;
        font-size: 18px;
        font-weight: 600;
        line-height: 140%;
    }

    p.content {
        border-bottom: 1px solid #EBEDF4;
        padding-bottom: 20px;
        margin: 0;
    }

    p.file {
        position: relative;
        color: #515151;
        font-size: 18px;
        font-weight: 600;
        line-height: 140%;
        padding-left: 30px;
        margin: 10px 0 0 5px;
    }

    p.file::before {
        content: '';
        position: absolute;
        width: 20px;
        height: 20px;
        left: 0;
        top: 3px;
        background-image: url(@/assets/icons/file_down.png);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
    }

    .list-file {
        display: flex;
        align-items: center;
        margin-top: 15px;
        padding-bottom: 20px;
        border-bottom: 1px solid #EBEDF4;
    }

    .wp-file {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-basis: 30%;
        border-radius: 40px;
        background:rgba(240, 48, 48, 0.20);
        padding: 10px;
        color: #515151 !important;
        font-size: 15px;
        font-weight: 500;
        line-height: normal;
        text-decoration: none !important;
        margin-right: 10px;
    }

    span.file_name {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
    }

    .wp-file:last-child {
        margin-right: 0;
    }

    .wp-file img {
        width: 20px;
        height: 20px;
    }

    .wp-file .note_ico {
        width: 25px;
        height: 25px;
        margin-right: 10px;
    }

    button.reply {
        color: #FFF;
        text-align: center;
        font-size: 16px;
        font-weight: 600;
        line-height: normal;
        border-radius: 54px;
        background: #F03030;
        padding: 10px 20px;
        margin-top: 10px;
    }
    
</style>