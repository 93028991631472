<template>
    <div class="regular">
        <h5>Quản lý sản phẩm</h5>
        <div class="breadcrumb">
            <span>Quản lý sản phẩm</span>
            <span style="margin: 0 8px;"><img src="@/assets/icons/caret-right.png" alt=""></span>
            <span class="active">Danh sách sản phẩm</span>
        </div>
        <el-row>
            <el-col :span="7">
                <div class="wp-cate">
                    <p class="ttl">Các gói dịch vụ</p>
                    <ul class="list-cate">
                        <li 
                            :class="{'active' : keyActive == item.id}"
                            v-for="(item, index) in listSettings"
                            :key="index"
                            @click="keyActive = item.id, ttl = item.name, getDataDetail(item.id)"
                        >
                            {{ item.name }}
                        </li>
                    </ul>
                </div>
            </el-col>
            <el-col :span="17" v-if="detailPage">
                <div class="wp-detail">
                    <div class="d-flex align-items-center justify-content-between mb-3">
                        <p class="ttl">{{ ttl }}</p>
                        <div class="d-flex">
                            <button class="btn_store" v-if="keyActive == 2" @click="$router.push('/quan-ly-san-pham/kho-tai-khoan')">Kho tài khoản</button>
                            <button class="btn_cancel" @click="detailPage = false, keyActive = 0, getDataList()">Hủy bỏ</button>
                            <button class="btn_save" @click="updateForm('formUpdate')">Lưu chỉnh sửa</button>
                        </div>
                    </div>

                    <div >
                        <p class="mb-1 fw-medium">Chi tiết gói</p>
                        <el-form 
                            ref="formUpdate"
                            class="form_public"
                            :rules="rules"
                            :model="dataDetail"
                        >
                            <el-form-item prop="name" label="Tên gói">
                                <el-input type="text" placeholder="Nhập tên gói" v-model="dataDetail.name"></el-input>
                            </el-form-item>
                            <p class="label label--rq">Ảnh bìa</p>
                            <div class="wp-img">
                                <input type="file" @change="handleFileChange" accept="image/*" style="display: none" ref="fileInput">
                                <div class="wp-upload" @click="$refs.fileInput.click()" @dragover.prevent @dragenter.prevent @drop="handleDrop">
                                    <div v-if="!image_url">
                                        <img src="@/assets/icons/Collectibles.png" alt="">
                                        <p>Bấm để chọn ảnh cần tải lên</p>
                                        <p class="des">hoặc kéo thả ảnh vào đây</p>
                                    </div>
                                    <img :src="$storage + image_url" alt="" v-else style="max-width: 220px; max-height: 141px;" @error="handleError">
                                </div>
                            </div>
                            <div v-if="keyActive == 1">

                                <el-form-item prop="price" label="Giá gói">
                                    <el-input type="text" placeholder="Nhập giá tổng" v-model="dataDetail.price"></el-input>
                                    <span class="unit_ico">$</span>
                                </el-form-item>
                                <span class="note">Giá đặt cọc + giá thanh toán nốt = Giá gói</span>
                                <div class="ms-5">
                                    <el-form-item prop="deposits" label="Giá đặt cọc">
                                        <el-input type="text" placeholder="Nhập giá thanh toán lần 1" v-model="dataDetail.deposits"></el-input>
                                        <span class="unit_ico">$</span>
                                    </el-form-item>
                                    <el-form-item prop="price_exist" label="Giá thanh toán nốt">
                                        <el-input type="text" placeholder="Nhập giá thanh toán lần 2" v-model="dataDetail.price_exist"></el-input>
                                        <span class="unit_ico">$</span>
                                    </el-form-item>
                                </div>
                            </div>
                            <p class="label label--rq">Mô tả nhanh</p>
                            <div class="ms-4">
                                <el-form-item prop="description" :label="'Mô tả ' + (index + 1)" v-for="(item, index) in dataDetail.quick_description">
                                    <el-input type="text" placeholder="Nhập mô tả" v-model="item.description"></el-input>
                                </el-form-item>
                            </div>
                            <p class="label label--rq">Mô tả chi tiết</p>
                            <CKeditor 
                                :editorData="dataDetail.description"
                                @getData="getDataDes"
                            />
                            <hr>
                            <div v-if="keyActive == 1">
                                <h6 class="ttl">Form khảo sát</h6>
                                <div style="position: relative;" v-for="(item, index) in dataDetail.survey">
                                    <el-form-item prop="question" :label="'Câu ' + (index + 1)">
                                        <el-input type="text" placeholder="Nhập câu hỏi" v-model="item.question"></el-input>
                                    </el-form-item>
                                    <button class="btn_del_sur" type="button" @click="dataDetail.survey.splice(index, 1)">x</button>
                                </div>
                                <div class="text-end">
                                    <button class="add_ques" type="button" @click="addQues">+ Thêm câu hỏi</button>
                                </div>
                                <hr>
                                <h6 class="ttl">Yêu cầu tải file</h6>
                                <el-form-item prop="file_description" label="Nhập những yêu cầu đối với file tải lên">
                                    <el-input type="textarea" placeholder="Nhập yêu cầu" v-model="dataDetail.file_description"></el-input>
                                </el-form-item>
                                <hr>
                                <h6 class="ttl">Máy ảo</h6>
                                <el-form-item prop="virtual_price" label="Giá thuê tài khoản máy ảo trên tháng">
                                    <el-input type="text" placeholder="Nhập giá" v-model="dataDetail.virtual_price"></el-input>
                                    <span class="unit_ico">$</span>
                                </el-form-item>
                            </div>
                        </el-form>
                    </div>
                </div>
            </el-col>
        </el-row>
    </div>
</template>

<script>
import { handleError } from 'vue';
import CKeditor from '../components/ckeditor.vue'
export default {
    components: { CKeditor},
    data() {
        return {
            keyActive: 0,
            listSettings: [],
            ttl: '',
            detailPage: false,
            dataDetail: [],
            rules: {
                name: [{required: true, message: "Vui lòng nhập tên gói", trigger: ["blur", "change"]}],
                price: [{required: true, message: "Vui lòng nhập giá gói", trigger: ["blur", "change"]}],
                deposits: [{required: true, message: "Vui lòng nhập giá đặt cọc", trigger: ["blur", "change"]}],
                price_exist: [{required: true, message: "Vui lòng nhập giá thanh toán nốt", trigger: ["blur", "change"]}],
                file_description: [{required: true, message: "Vui lòng nhập yêu cầu", trigger: ["blur", "change"]}],
                virtual_price: [{required: true, message: "Vui lòng nhập giá máy ảo", trigger: ["blur", "change"]}],
            },
            image_url: ''
        }
    },
    mounted() {
        this.getDataList()
    },
    methods: {
        getDataDes(data) {
            this.dataDetail.description = data
        },
        addQues() {
            const item = {question: ''}
            this.dataDetail.survey.push(item)
        },
        async handleFileChange(event) {
            let url_image = await this.uploadFile('product/files/', event.target.files[0])
            this.image_url = url_image.path_temp
            this.dataDetail.thumbnail = url_image.path
        },
        async handleDrop(e) {
            e.preventDefault();
            const file = e.dataTransfer.files[0];
            let url_image = await this.uploadFile('product/files/', file)
            this.image_url = url_image.path_temp
            this.dataDetail.thumbnail = url_image.path
        },
        async getDataList() {
            this.startLoading();
            await this.$axios.get('/api/admin/getListPackage',{
                headers: {
                    Authorization: "bearer " + window.localStorage.getItem('admin'),
                }
            }).then(res => {
                this.listSettings = res.data.data
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", 'Vui lòng thử lại!');
                    }
                }
            })
            this.endLoading();
        },
        async getDataDetail(id) {
            this.detailPage = true
            this.startLoading();
            await this.$axios.get('/api/admin/getPackageDetail?id=' +id, {
                headers: {
                    Authorization: "bearer " + window.localStorage.getItem('admin'),
                }
            }).then(res => {
                this.dataDetail = res.data.data
                this.image_url = res.data.data.thumbnail
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", 'Vui lòng thử lại!');
                    }
                }
            })
            this.endLoading();
        },
        async updateForm(formName) {
            this.$refs[formName].validate(async (valid) => {
                if (valid) {
                    let list_question = this.dataDetail.survey.map(item => item.question)
                    if(!list_question.includes('')) {
                        this.startLoading();
                        await this.$axios.post('/api/admin/updatePackage', this.dataDetail, {
                            headers: {
                                Authorization: "bearer " + window.localStorage.getItem('admin'),
                            }
                        }).then(res => {
                            this.showNotify("success", "Thành công", 'Chỉnh sửa thông tin thành công');
                            this.getDataList()
                            this.getDataDetail(this.dataDetail.id)
                            this.$refs.fileInput.value = null;
                        }).catch(err => {
                            if(err.response) {
                                if(err.response.status === 401) {
                                    this.handleCheckTokenAuth();
                                } else {
                                    this.showNotify("error", "Thất bại", err.response.data.error);
                                }
                            }
                        })
                        this.endLoading();
                    } else {
                        this.showNotify("warning", "Lưu ý", 'Vui lòng nhập đủ câu hỏi');
                    }
                } else {
                   return true
                }
            });
        }
    }
}
</script>
<style lang="css" scoped>
    .regular {
        color: #515151;
    }

    h5 {
        font-size: 24px;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: 0.24px;
        margin-bottom: 10px;
    }

    .breadcrumb {
        font-family: 'Public Sans', sans-serif;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.07px;
    }

    .breadcrumb .active {
        color: #2367EC;
    }

    button {
        background: none;
        border: none;
        outline: none;
        font-family: 'Public Sans', sans-serif;
        font-size: 14px;
        font-weight: 500;
        border-radius: 15px;
        padding: 10px 14px;
        height: 40px;
        display: flex;
        align-items: center;
    }

    .wp-cate {
        border-radius: 20px;
        background: #FFF;
        padding: 20px;
        margin-right: 20px;
    }

    .wp-cate p.ttl, .wp-detail p.ttl {
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 0;
    }

    .wp-detail p.ttl {
        margin-bottom: 10px;
    }

    .btn_cancel {
        background:#EBEDF4;
        color: #F03030;
    }

    .wp-detail {
        background-color: #FFF;
        border-radius: 20px;
        padding: 20px;
    }

    ul.list-cate {
        padding: 0;
        list-style: none;
        margin-top: 25px;
    }

    ul.list-cate li {
        border-radius: 20px;
        background: #F3F3F3;
        padding: 9.5px 12px;
        margin-bottom: 10px;
        color: #515151;
        font-size: 14px;
        font-weight: 500;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
    }

    ul.list-cate li.active {
        background: rgba(30, 219, 128, 0.50);
    }

    ul.list-cate li:last-child {
        margin-bottom: 0;
    }

    .wp-detail button.btn_save {
        color: #FFF;
        font-size: 14px;
        font-weight: 500;
        border-radius: 15px;
        background: #2367EC;
        margin-left: 10px;
    }

    .wp-detail button.btn_delete {
        color: #FFF;
        font-size: 14px;
        font-weight: 500;
        border-radius: 15px;
        background: #ee3439;
        margin-left: 10px;
    }

    .wp-detail button {
        width: 160px;
        display: flex;
        justify-content: center;
    }

    .wp-img {
        background-color: #EBEDF4;
        border-radius: 20px;
        padding: 20px;
        margin-bottom: 10px;
        text-align: center;
        cursor: pointer;
    }

    .wp-upload p {
        margin-bottom: 0;
        color:#515151;
        font-size: 15px;
        line-height: 150%;
    }

    .wp-upload p.des {
        color:#9B9B9B;
        font-size: 13px;
    }

    p.label {
        color: #515151;
        font-size: 15px;
        font-weight: 500;
        line-height: 180%;
        margin-bottom: 0;
        height: 32px;
        padding-left: 10px;
        position: relative;
    }

    p.label--rq::before {
        content: '*';
        position: absolute;
        left: 0;
        color: #f56c6c;
    }

    span.unit_ico {
        color: #515151;
        font-size: 15px;
        font-weight: 500;
        line-height: 100%;
        position: absolute;
        right: 20px;
    }

    span.note {
        color: #515151;
        font-size: 14px;
        font-weight: 400;
        line-height: normal;
    }

    button.add_ques {
        display: inline-block;
        color: #FFF;
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0.07px;
        border-radius: 15px;
        background: #F58E16;
        width: 193px;
        height: 40px;
        margin-top: 10px
    }

    button.btn_store {
        color: #FFF;
        background: #F03030;
        margin-right: 10px;
    }

    .btn_del_sur {
        position: absolute;
        top: -6px;
        padding: 0;
        right: 20px;
        width: unset !important;
        font-size: 18px;
        color: #ee3934;
        font-weight: 500;
    }
</style>

<style>
    .form_public .el-textarea__inner {
        border-radius: 15px;
        background:#EBEDF4;
        padding: 10px 12px;
        resize: none;
        min-height: 90px !important;
    }
</style>